import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

const KidKraftPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Summer Engineering Intern: KidKraft</h1>
            <p>For the summers of 2017 and 2018 I worked for <a href="https://www.kidkraft.com/">KidKraft</a>, an international toy company based out of Dallas, TX. My job title was "Summer Engineering Intern" and my responsibilities were about just as vague. I worked for the various engineering departments completing a variety of task throughout the product development lifecycle. Primarily I worked with the Technical Quality Engineering group. This team was responsible for Quality Assurance and Safety Compliance. One of my key responsibilities was to proofread print and digital assembly instructions and provide revisions. I also helped to assemble samples from China to check for accuracy and prepare for photography. Finally, with this group, I helped to maintain the internal documentation around the various product/product lines.</p>
            <p>I also worked with the Product Development group. There my responsibilities were primarily Computer Aided Design (CAD) related. I often worked to adapt artistic models into well-structured SolidWorks models without altering the item's feel. Additionally, for the Product Development group, I prepared numerous quote packets for new items. Finally, I got to design parts which could be injection molded and helped the engineering groups to explore the use of SolidWorks' simulation features to validate safety compliance prior to manufacture.</p>
            <p>Perhaps its better to just do bullet points:</p>
            <ul>
                <li>Take art models and convert to production designs:
                    <ul>
                        <li>Follow internal design ideas and best practices</li>
                        <li>Make sure parts are the correct dimensions (i.e. thickness) based on what factory uses</li>
                        <li>Break multi body parts into two or more part files</li>
                        <li>Properly mate things without `fix` and `inplace` constraints</li>
                        <li>Unwind incredible dependency structures where the part's shape is defined in context and then mated based off the shape (I can't recreate this one in SolidWorks without it creating a warning or error)</li>
                        <li>Designed injection molded items from sketches or blender models with an emphasis on no undercut design</li>
                    </ul>
                </li>
                <li>Spin up new products:
                    <ul>
                        <li>Consider yielding: could one part be made of the cutouts of another? Would a minor dimensional change make this possible?</li>
                        <li>Keep the ERP system updated with changes to parts and estimates on cost.</li>
                        <li>Layout panels into sheets for artists to reference location of holes and cutouts when designing.</li>
                        <li>Prepare quote packets for sending to international factories.</li>
                        <li>Assemble and Review pre-production samples for accuracy, defects, and other changes that may need to be made.</li>
                        <li>Proofread printed and digital instructions for correctness, giving feedback on modifications where necessary.</li>
                    </ul>
                </li>
                <li>Help with compliance:
                    <ul>
                        <li>Do various testing processes in house to quickly make go/no-go decisions before involving external testing facilities.</li>
                        <li>Begin experimenting with SolidWorks center of mass calculation to digitally model tipping points before production samples</li>
                        <li>Track, test, and update outdoor components that had been sitting in the parking lot to test sun damage.</li>
                    </ul>
                </li>
                <li>Help with customer support:
                    <ul>
                        <li>Answer the random (and quite varied) questions that customer support would have about a product (ex. the height in meters of a backyard play-place)</li>
                        <li>Make one-off changes for display models (ex. add screws so that doors can't open).</li>
                    </ul>
                </li>
            </ul>
            <p>It was a fun time and I was closer than many people would expect to dropping out of college and staying there full time (after all, what 19 year old doesn't want a full time office job (with benefits!)). The best perk was that, as a 19 year old, I could name most of the Disney Princesses and describe them which was just an awesome party trick. In the end, I went and finished my computer science degree, and in summer 2019, they had to hire five interns to replace me.</p>
            <p>Some of the items I worked on:</p>
            <ul>
                <li><a href="https://www.kidkraft.com/us_en/so-stylish-mansion-dollhouse.html">So Stylish Mansion</a> - This was particularly fun because if opened the wrong way the pre assembled components may snap so we had to make the <a href="http://images.salsify.com/image/upload/s--gOLz1kiZ--/aio8enfywz2ur2bz7hli.pdf">assembly instructions</a> very clear on this fact without using words </li>
                <li><a href="https://www.kidkraft.com/us_en/farm-to-table-play-kitchen-53411.html">Farm To Table Kitchen</a> - This one was something I got to work with both summers at different stages of the lifecycle which gave a unique perspective</li>
                <li><a href="https://www.kidkraft.com/us_en/grand-horse-stable-and-dollhouse-20055.html">Grand Horse Stable</a> - I did a first pass on the injection molded wheelbarrow for this item; no idea if its changed before production</li>
            </ul>
            {FooterBar()}
        </div>
    )
}

export default KidKraftPage